import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <div>
    <Favicon url="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574866282/UQAM_Symposium/favicon_2_wheel_orange.png" />
    <App />
  </div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
