import React, { Component } from 'react';
import './App.css';
var _ = require('lodash');


class SliderLandingPage extends Component {

  constructor(props) {
     super(props);
       this.state = {
         flexDirection: null,
         leftColor: null,
         rightColor: null,
         shuffledLeftColors: null,
         shuffledRightColors: null
       };
   }

  componentDidMount(){
    
    let shuffledColors = _.shuffle(this.props.projectColors)
    let shuffledPosition = _.shuffle(this.props.position);

    this.setState({
      leftColor: shuffledColors[0],
      rightColor: shuffledColors[1]
    }, () => {

      let leftArrayOfImages = this.filterImagesPerColor(
        this.state.leftColor, this.state.rightColor
        ).shuffledLeftColors;


      let rightArrayOfImages = this.filterImagesPerColor(
        this.state.leftColor, this.state.rightColor
        ).shuffledRightColors;

      let shuffledLeftColors = _.shuffle(leftArrayOfImages);
      let shuffledRightColors = _.shuffle(rightArrayOfImages);

      this.setState({
        shuffledLeftColors,
        shuffledRightColors
      })
    })
  }


  filterImagesPerColor = (colorLeft, colorRight) => {

    if(!this.props.masterImages){
      return null;
    }

    let colorLeftCode = colorLeft.color;
    let colorRightCode = colorRight.color;

    let filteredMasterArrayLeft = this.props.masterImages.filter(ele => !ele.includes(colorLeftCode));
    let filteredMasterArrayRight = this.props.masterImages.filter(ele => !ele.includes(colorRightCode));

    let shuffledLeftColors = _.shuffle(filteredMasterArrayLeft);
    let shuffledRightColors = _.shuffle(filteredMasterArrayRight);

    return {
      shuffledLeftColors,
      shuffledRightColors
    }



  }

  renderContent = () => {

    if(!this.state.shuffledLeftColors
      && !this.state.shuffledRightColors){
      return null
    }

    return(
      <div
        style={{"flexDirection": this.state.flexDirection}}
        className="slider_landing_page">
        <div style={{backgroundColor: this.state.leftColor.value}} className="slide_inner">
          <img src={this.state.shuffledLeftColors[0]} />
        </div>
        <div style={{backgroundColor: this.state.rightColor.value}} className="slide_inner">
          <img src={this.state.shuffledRightColors[0]} />
        </div>
      </div>
    )
  }


  render(){
    return(
      <div>
        {this.renderContent()}
      </div>
    )
  }
}

export default SliderLandingPage;
