import React, { Component } from 'react';
import { ShakeLittle, ShakeSlow, ShakeHorizontal } from 'reshake'
import './App.css';
var _ = require('lodash');
var parse = require('html-react-parser');


class ProgramComponent extends Component {

  constructor(props) {
     super(props);
       this.state = {
         flexDirection: null,
         leftColor: null,
         rightColor: null,
         shuffledLeftColors: null,
         shuffledRightColors: null
       };
   }

  componentDidMount(){
    let shuffledColors = _.shuffle(this.props.projectColors)
    let shuffledPosition = _.shuffle(this.props.position);

    this.setState({
      leftColor: shuffledColors[0],
      rightColor: shuffledColors[1]
    }, () => {

      let leftArrayOfImages = this.filterImagesPerColor(this.state.leftColor, this.state.rightColor).shuffledLeftColors;
      let rightArrayOfImages = this.filterImagesPerColor(this.state.leftColor, this.state.rightColor).shuffledRightColors;

      let shuffledLeftColors = _.shuffle(leftArrayOfImages);
      let shuffledRightColors = _.shuffle(rightArrayOfImages);

      this.setState({
        shuffledLeftColors,
        shuffledRightColors
      })
    })
  }


  filterImagesPerColor = (colorLeft, colorRight) => {

    if(!this.props.masterImages){
      return null;
    }

    let colorLeftCode = colorLeft.color;
    let colorRightCode = colorRight.color;

    let filteredMasterArrayLeft = this.props.masterImages.filter(ele => !ele.includes(colorLeftCode));
    let filteredMasterArrayRight = this.props.masterImages.filter(ele => !ele.includes(colorRightCode));

    let shuffledLeftColors = _.shuffle(filteredMasterArrayLeft);
    let shuffledRightColors = _.shuffle(filteredMasterArrayRight);

    return {
      shuffledLeftColors,
      shuffledRightColors
    }

  }

  returnDefinedConent = (data) => {
    if(data){
      return data;
    }
  }

  renderContent = () => {

    if(!this.state.shuffledLeftColors
      && !this.state.shuffledRightColors){
      return null
    };

    let data = this.props.data;



    return(
      <div>
        <div className="hour">
          <h2>{this.props.hour}</h2>
        </div>
        <div className="inner">
          <div className="headline">
            <h2>{data[0]}</h2>
          </div>
          <div className="speakers">
            <h2>{data[1]}</h2>
          </div>
          <div className="moderators">
            <h2>{data[2]}</h2>
          </div>
        </div>
    </div>
    )
  }


  render(){
    if(!this.props.data){
      return null;
    }
    return(
      <div>
        {this.renderContent()}
      </div>
    )
  }
}

export default ProgramComponent;
