import React, { Component } from 'react';
import _ from "lodash"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import SeparatorComponent from "./SeparatorComponent.js";
import ProgramComponent from "./ProgramComponent.js"
import StickyComponent from "./StickyComponent.js";
import parse from 'html-react-parser';
import './App.css';
import axios from 'axios';

class App extends Component {

  constructor(props) {
     super(props);
       this.state = {
         counter: 0,
         slideCounter: 0,
         isScrolling: false,
         loop: 0,
         rawData: null,
         mainInfo: null,
         mainText: null,
         propositionText: null,
         notesText: null,
         infosPratiques: null,
         stickyBubbles: [
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574293702/UQAM_Symposium/button_contexte.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574293781/UQAM_Symposium/button_presentation-02.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574294235/UQAM_Symposium/button_proposition.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574308413/UQAM_Symposium/button_infos-02-02.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574295097/UQAM_Symposium/button_inscrire-01.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1577312464/UQAM_Symposium/programme.svg"
         ],
         rollingImages: [
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238007/UQAM_Symposium/wheel_orange.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238005/UQAM_Symposium/earth_blue.svg",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574296468/UQAM_Symposium/apple_blue.png",
           "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574202347/UQAM_Symposium/recycle_orange.svg"
         ],
         masterImages: [
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238005/UQAM_Symposium/text_green.svg",
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574307108/UQAM_Symposium/text_2_blue.svg",
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238005/UQAM_Symposium/text_blue.svg",
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238006/UQAM_Symposium/text_orange.svg",
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573241841/UQAM_Symposium/image_green.svg",
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238003/UQAM_Symposium/image_orange.svg",
             "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573238003/UQAM_Symposium/recycle_green.svg",
           ],
         projectColors: [
           {
             color: "blue",
             value: "#0710ac"
           },
           {
             color: "orange",
             value: "rgb(255, 81, 0)"
           },
           {
             color: "green",
             value: "rgb(0, 195, 52)"
           }
         ],

         position: [
           "row", "rowReverse"
         ]
       };
   }

  componentDidMount(){

    window.addEventListener("scroll", this.scrollHandler);
    window.addEventListener('scroll', _.debounce(() => {
      this.setState({
        isScrolling: false,
        counter: this.state.counter+1
      }, () => {
        if(this.state.counter === this.state.rollingImages.length){
          this.setState({
            counter: 0
          })
        }
      })
    }, 500))
    axios.get("https://sheet.best/api/sheets/1ea84b22-3c1c-4ca3-8b92-c28b288b5d3a")
    .then((data) => {
      this.setState({
        rawData: data.data,
        mainInfo: data.data.filter(ele => ele.section === "main_info")[0],
        mainText: data.data.filter(ele => ele.section === "main_text")[0],
        propositionText: data.data.filter(ele => ele.section === "proposition_text")[0],
        notesText: data.data.filter(ele => ele.section === "notes_text")[0],
        infosPratiques: data.data.filter(ele => ele.section === "infos_pratiques")[0],
        isProgram: data.data.filter(ele => ele.section === "afficher_programme")[0].section_1,
        programContent: data.data.filter(ele => ele.section === "infos_programme"),
        programPdf: data.data.filter(ele => ele.section === "lien_pdf_programme")[0]
      })
    })
    .catch(function (error) {
      console.log(error);
    })
  }


  scrollHandler = () => {
    this.setState({
      isScrolling: true
    })
  }


    glyphSplitter = (stringToSplit, glyph) => {
      return stringToSplit.split(glyph)
    };

    spanMaker = (array) => {
      return array.map((ele, index) => {
        return (
          <span key={index}>{ele}</span>
        )
      })
    }

    listMaker = (array) => {
      return array.map((ele, index) => {
        return (
          <li key={index}>{ele}</li>
        )
      })
    }


    renderMainInfo = () => {
      if(this.state.mainInfo === null){
        return null;
      }
      let formattedHeadline = this.glyphSplitter(this.state.mainInfo.section_1, "¬");
      let formattedArgument = this.glyphSplitter(this.state.mainInfo.section_2, "¬");
      let formattedCommittee = this.glyphSplitter(this.state.mainInfo.section_3, "¬");
      let splitedFormattedHeadline = this.glyphSplitter(formattedHeadline[0], ":");
      let splitedFormatedHeadlineKeywords = this.glyphSplitter(splitedFormattedHeadline[1], ",");
      let formattedDate = this.glyphSplitter(formattedHeadline[1], ",");

      if(this.state.isScrolling){
        return (
          <section>
            <div className="full_main_container">
            <header>
              <div
              className="full_main_container_header">
                <h1>{splitedFormattedHeadline[0]}:
                <span>{splitedFormatedHeadlineKeywords[0]}</span>,
                <span>{splitedFormatedHeadlineKeywords[1]}</span>,
                <span>{splitedFormatedHeadlineKeywords[2]}</span>
                </h1>
              </div>
            </header>
            </div>
            <div className="full_main_container_img">
              <img src={this.state.rollingImages[this.state.counter]} />
            </div>
            <div
            className="full_main_container">
            <footer>
              <div>
                <h1>{formattedDate[0]},
                <span>{formattedDate[1]}</span>
                </h1>
              </div>
            </footer>
            </div>
          </section>
        )
      }
    }

    formatBool = (string) => {
      if(string === "TRUE"){
        return true
      }else{
        return false
      }
    }

    renderFormattedLinks = (ele) => {
      if(ele.section_3){
        let data = ele.section_3;
        return data.split("¬")
      }else{
        return;
      }
    }

    renderProgramPdf = () => {
      let programPdf = this.state.programPdf
      if(!programPdf){
        return null;
      }

      let programPdfCopy = programPdf.section_1;
      let programPdfLink = programPdf.section_2;
      return (
        <div className="programme_pdf">
          <h2>
            <a href={programPdfLink} target="_blank">
            {programPdfCopy}
            </a>
          </h2>
        </div>
      )
    }


    renderInscriptionButtonOnProgram = () => {
      return (
        <div>
          <h2>
            Entrée libre mais réservez votre place ici:
            <a href="https://www.eventbrite.ca/e/le-design-graphique-au-xxie-siecle-etudier-creer-agir-tickets-84053276613" target="_blank">
              dgr21-uqam.eventbrite.ca
             </a>
          </h2>
        </div>
      )
    }



    renderProgram = () => {
      if(!this.state.isProgram){
        return null
      };

      let isProgram = this.formatBool(this.state.isProgram);

      let programMaped = this.state.programContent
      .slice(0, 8)
      .map((ele, index) => {

        let formattedProgramBody = this.glyphSplitter(ele.section_2, "¬");
                
        let formattedHour = ele.section_1;
        let formattedLinks = this.renderFormattedLinks(ele);

        return (
          <div>
          <ProgramComponent
            key={index}
            {...this.state}
            hour={formattedHour}
            data={formattedProgramBody}
            links={formattedLinks}
          />
          </div>
        )
      })

      let programMaped2 = this.state.programContent
      .slice(8, 16)
      .map((ele, index) => {
        let formattedProgramBody = this.glyphSplitter(ele.section_2, "¬");
        let formattedHour = ele.section_1;

        return (
          <div>
          <ProgramComponent
            key={index}
            {...this.state}
            hour={formattedHour}
            data={formattedProgramBody}
          />
          </div>
        )
      })

      if(!isProgram){
        return null
      }
      return (
        <div
         id="programme"
        className="program_section">
          <section>
          <div className="program_section_first">
            <div className="program_headline">
              <h1>Programme</h1>
              {this.renderInscriptionButtonOnProgram()}
              {this.renderProgramPdf()}
            </div>
            {programMaped}
          </div>
          <div className="program_section_second">
            {programMaped2}
          </div>
          </section>
        </div>
      )
    }




    renderScrollingHeadlines = () => {
      if(this.state.mainInfo === null){
        return null;
      }
      let formattedHeadline = this.glyphSplitter(this.state.mainInfo.section_1, "¬");
      return (
        <section className="full_title_container">
          <div className="full_main_container_inner">
            <h1>LE DESIGN GRAPHIQUE AU XXIe SIÈCLE : <span>ÉTUDIER</span>, <span>CRÉER</span>, <span>AGIR</span></h1>
            <h2>{formattedHeadline[1]}</h2>
            <h3>{formattedHeadline[2]}</h3>
          </div>
        </section>
      )
    }


    renderCommitteeText = () => {
      if(this.state.mainInfo === null){
        return null;
      }
      let formattedDay = this.glyphSplitter(this.state.mainInfo.section_2, "¬");

      let formattedCommittee = this.glyphSplitter(this.state.mainInfo.section_3, "¬");

      return (
        <section className="full_committee_container">
          <div className="full_committee_container_inner">
            <div>
              <h4>{formattedDay}</h4>
            </div>
            <div>
              <h4>{formattedCommittee[0]}</h4>
            </div>
          </div>
        </section>
      )
    }


    renderContextText = () => {
      if(this.state.mainText === null){
        return null;
      }

        let formattedContext = this.glyphSplitter(this.state.mainText.section_1, "¬");
        let formattedArgument = this.glyphSplitter(this.state.mainText.section_2, "¬");

        return (
          <section id='contexte' className="main_text_container">
            <section className="main_text_container_inner">
            <div className="main_text_container_inner_contexte">
              <span>{parse(formattedContext[0])}</span>
              <p>{parse(formattedContext[1])}</p>
            </div>
            </section>
          </section>
        )
    }


    renderArgumentText = () => {
      if(this.state.mainText === null){
        return null;
      }

      let formattedArgument = this.glyphSplitter(this.state.mainText.section_2, "¬");

      return (
        <section id='argument' className="main_text_container">
          <div className="main_text_container_inner_argument">
            <div>
              <span>{parse(formattedArgument[0])}</span>
            </div>
          </div>
          <div className="main_text_container_inner_argument_body">
            <div>
                <p>{parse(formattedArgument[1])}</p>
                <p>{parse(formattedArgument[2])}</p>
            </div>
            <div>
                <p>{parse(formattedArgument[3])}</p>
                <p>{parse(formattedArgument[4])}</p>
            </div>
          </div>
          <div className="main_text_container_inner_argument_body_last">
            {parse(formattedArgument[5])}
          </div>
      </section>
      )

    }



    renderPropositionText = () => {
      if(this.state.propositionText === null){
        return null;
      }
      let formattedProposalDate = this.glyphSplitter(this.state.propositionText.section_1, "¬");
      let formattedProposalSubmission = this.glyphSplitter(this.state.propositionText.section_2, "¬");
      let formattedProposalSuggestions = this.glyphSplitter(this.state.propositionText.section_3, "¬");
      let formattedProposalsGuidelines =  this.glyphSplitter(this.state.propositionText.section_4, "¬");
      let splittedFormattedProposalSuggestions1 = this.glyphSplitter(formattedProposalSuggestions[1], ":");
      let splittedFormattedProposalSuggestions2 = this.glyphSplitter(formattedProposalSuggestions[2], ":");
      let splittedFormattedProposalSuggestions3 = this.glyphSplitter(formattedProposalSuggestions[3], ":");
      return (
          <section
          id="proposal"
          className="proposition_text_main_container">
              <section className="proposition_text_main_container_inner">
              <div className="proposition_text_main_container_inner_headlines">
                <span>
                  {formattedProposalDate}
                </span>
              </div>
              <p>{formattedProposalSubmission[1]}</p>
              <div className="proposition_text_main_container_title_list_container">
              </div>
              <div className="proposition_text_main_container_span_list_container">
                <div>
                  <h1>{formattedProposalSuggestions[0]}</h1>
                <ul>
                   <li>
                     <span>{splittedFormattedProposalSuggestions1[0]}:</span>
                     {splittedFormattedProposalSuggestions1[1]}
                   </li>
                   <li>
                     <span>{splittedFormattedProposalSuggestions2[0]}:</span>
                     {splittedFormattedProposalSuggestions2[1]}
                   </li>
                 </ul>
                </div>
                <div>
                <ul className="last_ul">
                  <li>
                    <span>{splittedFormattedProposalSuggestions3[0]}:</span>
                    {splittedFormattedProposalSuggestions3[1]}
                  </li>
                  <li className="last_li">{formattedProposalSuggestions[4]}</li>
                 </ul>
                </div>
              </div>
              </section>
          </section>
        )
    }

    renderFormattedGuidelines = () => {
        if(this.state.propositionText === null){
          return null;
        }
        let formattedGuidelinesAndButton = this.glyphSplitter(this.state.propositionText.section_4, "¬");
        let splittedFormattedGuidelines1 = this.glyphSplitter(formattedGuidelinesAndButton[0], ":")
        let splittedFormattedGuidelines2 = this.glyphSplitter(formattedGuidelinesAndButton[1], ":")

        return (
          <section
          id="attended_format"
          className="guidelines_and_button_container">
          <div className="guidelines_and_button_container_inner">
            <div className="guidelines_container">
              <div>
                <span>{splittedFormattedGuidelines1[0]}</span>
                {splittedFormattedGuidelines1[1]}
                </div>
                <div>
                  <span>{splittedFormattedGuidelines2[0]}</span>
                  {splittedFormattedGuidelines2[1]}
                </div>
            </div>
            </div>
          </section>
        )

    }


    renderNotesTextes = () => {
      if(this.state.notesText == null){
        return null;
      }
        let formattedNotes = this.glyphSplitter(this.state.notesText.section_1, "¬");
        return (
          <section className="notes_container">
            <div className="notes_container_inner">
              <div>
                <p><span>(1)</span>{formattedNotes[0]}</p>
              </div>
              <div>
                <p><span>(2)</span>{formattedNotes[1]}</p>
              </div>
              <div>
                <p><span>(3)</span>{formattedNotes[2]}</p>
              </div>
              <div>
                <p><span>(4)</span>{formattedNotes[3]}</p>
              </div>
            </div>
          </section>
        )
    }

    renderSeparatorImages = () => {
      if(!this.state.masterImages){
        return null;
      };
      return (
        <SeparatorComponent
          {...this.state}
        />
      )
    };


    renderSticky = () => {

      if(!this.state.stickyBubbles){
        return null;
      }

      let stickyBubbles = this.state.stickyBubbles;
      let isProgram = this.formatBool(this.state.isProgram);

      if(isProgram){
        return (
          <nav className="sticky_container">
            <ul>
            <AnchorLink href='#programme'>
                <StickyComponent data={stickyBubbles[5]} span={1}/>
              </AnchorLink>
              <AnchorLink href='#infos_pratiques'>
                <StickyComponent data={stickyBubbles[3]} span={2}/>
              </AnchorLink>
              <AnchorLink href='#inscription'>
                <StickyComponent data={stickyBubbles[4]} span={3}/>
              </AnchorLink>
              <AnchorLink href='#contexte'>
                <StickyComponent data={stickyBubbles[0]} span={4}/>
              </AnchorLink>
              <AnchorLink href='#argument'>
              <StickyComponent data={stickyBubbles[1]} span={5}/>
              </AnchorLink>
              <AnchorLink href='#proposal'>
                <StickyComponent data={stickyBubbles[2]} span={6}/>
              </AnchorLink>
            </ul>
          </nav>
        )
      }else{
        return (
          <nav className="sticky_container">
            <ul>
              <AnchorLink href='#contexte'>
                <StickyComponent data={stickyBubbles[0]} span={1}/>
              </AnchorLink>
              <AnchorLink href='#argument'>
              <StickyComponent data={stickyBubbles[1]} span={2}/>
              </AnchorLink>
              <AnchorLink href='#proposal'>
                <StickyComponent data={stickyBubbles[2]} span={3}/>
              </AnchorLink>
              <AnchorLink href='#infos_pratiques'>
                <StickyComponent data={stickyBubbles[3]} span={4}/>
              </AnchorLink>
              <AnchorLink href='#inscription'>
                <StickyComponent data={stickyBubbles[4]} span={5}/>
              </AnchorLink>
            </ul>
          </nav>
        )
      }
    }

    renderInfosPratiques = () => {
      if(!this.state.infosPratiques){
        return null;
      }

      let formattedTitle = this.glyphSplitter(this.state.infosPratiques.section_1, "¬");
      let splitedFormatedHeadline = this.glyphSplitter(formattedTitle[1], ":");
      let splitedFormatedHeadlineKeywords = this.glyphSplitter(splitedFormatedHeadline[1], ",")
      let formattedAddress = this.glyphSplitter(this.state.infosPratiques.section_2, "¬");

      return (
        <section id="infos_pratiques" className="bottom_info_main_container">
          <div className="bottom_info_container_inner">
            <div className="bottom_info_container_headlines">
              <h1>Infos pratiques</h1>
            </div>
            <div className="bottom_info_container_body">
              <h1>
                {formattedTitle[0]}:{splitedFormatedHeadline[0]}:
                <span>{splitedFormatedHeadlineKeywords[0]},</span>
                <span>{splitedFormatedHeadlineKeywords[1]},</span>
                <span>{splitedFormatedHeadlineKeywords[2]}.</span>
              </h1>
            </div>
            <div className="bottom_info_container_secondary">
              <div>
                <h1>{formattedAddress[0]}.{formattedAddress[1]}</h1>
              </div>
              <div>
                <h1>{formattedAddress[2]}.</h1>
                <h1>
                  <a
                    href={formattedAddress[4]}
                    target="_blank"
                    rel ="noopener noreferrer">
                    {formattedAddress[3]}
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </section>
      )
    }

    renderInscriptionButton = () => {

        if(this.state.propositionText === null){
          return null;
        }
        let formattedGuidelinesAndButton = this.glyphSplitter(this.state.propositionText.section_4, "¬");

      return (
        <section
          id="inscription"
          className="inscription_button_main_container">
          <div className="inscription_button_main_container_inner">
          <a
            href={formattedGuidelinesAndButton[2]}
            target="_blank"
            rel ="noopener noreferrer">
            <img src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1574295097/UQAM_Symposium/button_inscrire-01.svg" />
            </a>
          </div>
        </section>
      )
    }

    renderCredits = () => {
      return (
        <div className="credits_main_container">
          <div className="credits_main_container_inner">
          <div>
            <h2>Direction artistique: <span>Louise Paradis</span></h2>
          </div>
          <div>
            <h2>Design et programmation du site web: <span>
              <a
                href="http://75-20.com/"
                target="_blank"
                rel ="noopener noreferrer">
              75-20
              </a>
              </span>
            </h2>
          </div>
          </div>
        </div>
      )
    }

  render() {
      return(
        <div className="App">
        {this.renderSticky()}
         {this.renderSeparatorImages()}
         {this.renderProgram()}
         {this.renderMainInfo()}
         {this.renderCommitteeText()}
         {this.renderInfosPratiques()}
         {this.renderInscriptionButton()}
         {this.renderSeparatorImages()}
         {this.renderContextText()}
         {this.renderSeparatorImages()}
         {this.renderArgumentText()}
         {this.renderNotesTextes()}
         {this.renderSeparatorImages()}
         {this.renderPropositionText()}
         {this.renderSeparatorImages()}
         {this.renderCredits()}
        </div>
      )
    }
}

export default App;
