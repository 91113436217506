import React, { Component } from 'react';
import './App.css';
var _ = require('lodash');


class StickyComponent extends Component {

  constructor(props) {
     super(props);
       this.state = {
         renderBubble: false
       };
   }

   renderStickyComponentContent = () => {
     if(!this.props){
       return null;
     }
     return(
       <div>
         <li
           onMouseEnter={this.triggerBubble}
           onMouseLeave={this.unTriggerBubble}>
           <span>
           {this.props.span}
           </span>
           <div>
              {this.renderBubble()}
           </div>
         </li>
       </div>
     )
   }

   triggerBubble = () => {
     this.setState({
       renderBubble: true
     })
   }

   unTriggerBubble = () => {
     this.setState({
       renderBubble: false
     })
   }

   renderBubble = () => {
     if(!this.state.renderBubble){
       return  <img style={{visibility: "hidden"}} src={this.props.data} />
     }
     return  <img src={this.props.data} />
   }

  render(){
    return(
      <div>
        {this.renderStickyComponentContent()}
      </div>
    )
  }
}

export default StickyComponent;
